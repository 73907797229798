<template>
  <li class="border-b my-1">
    <div class="flex items-center gap-2">
      <div class="mr-2 p-1">
        <img
          v-if="item.image_url"
          :src="`https://cdn.speedsize.com/4190f681-07cb-43aa-92d2-6096b01e7b62/${item.image_url}/w_120,h_120,r_contain`"
          alt=""
          width="120"
          height="120"
        />
      </div>
      <div class="py-2 w-full">
        <div
          class="flex flex-col gap-1 md:justify-between md:items-center md:flex-row"
        >
          <div>
            <h3 class="uppercase font-featured font-bold text-sm m-0 mb-2">
              {{ item.product.name }}
            </h3>
            <div>
              <div class="text-xs font-bold font-featured my-2 gap-4">
                <span>{{ item.product.sku }}</span>
                <span v-if="item.customColour" class="text-blue-500">
                  {{ $t('Custom Colour') }}
                </span>
              </div>
              <button
                v-if="isBike"
                @click="toggleFullSpecCartModal(item.uid)"
                class="text-sm uppercase border border-blue-500 px-4 py-2 flex items-center gap-1 text-blue-500"
              >
                <ListIcon />
                {{ $t('Full Spec') }}
              </button>
            </div>

            <ul
              v-if="
                item.configurable_options && item.configurable_options.length
              "
            >
              <li
                v-for="option in item.configurable_options"
                :key="option.option_value"
                class="text-sm"
              >
                - {{ option.option_label }} {{ option.value_label }}
              </li>
            </ul>
          </div>

          <div class="flex items-center justify-between gap-2 md:gap-5">
            <CartMenuQty :item="item" />
            <div>{{ price }}</div>
          </div>
        </div>

        <div class="border-t flex justify-end pt-1 mt-3">
          <button
            type="button"
            @click="removeItemFromCart(payload)"
            class="text-red-500"
          >
            {{ $t('Remove') }}
            <CartTrack
              v-if="productRemoved"
              :product="productRemoved"
              action="remove"
              :btn-text="$t('Remove')"
            />
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isBannedShipping"
      class="mt-1 pt-2 pb-1 flex justify-end gap-5 border-t"
    >
      <div class="text-red-500 font-bold text-sm">
        {{ shippingMessage }}
      </div>
      <button
        type="button"
        @click="removeFromCart"
        class="text-red-500 uppercase font-featured text-sm font-bold"
      >
        {{ $t('Remove') }}
        <CartTrack
          v-if="productRemoved"
          :product="productRemoved"
          action="remove"
          :btn-text="$t('Remove')"
        />
      </button>
    </div>
  </li>
</template>

<script>
import config from 'config';
import { mapGetters, mapActions } from 'vuex';
import bannedShippingMixin from 'theme/mixins/bannedShippingMixin';
import CartTrack from 'theme/components/theme/bikebuilder/bloomreach/CartTrack';
import ListIcon from 'theme/components/theme/bikebuilder/graphql-cart/icons/ListIcon.vue';
import CartMenuQty from 'theme/components/theme/bikebuilder/graphql-cart/CartMenuQty.vue';
import CircleCrossIcon from 'theme/components/theme/bikebuilder/graphql-cart/icons/CircleCrossIcon.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    ListIcon,
    CartTrack,
    CartMenuQty,
    CircleCrossIcon
  },
  mixins: [bannedShippingMixin],
  data() {
    return {
      productRemoved: null
    };
  },
  computed: {
    ...mapGetters('esw', ['useEsw', 'getUserCurrency']),
    locale() {
      const availableCurrencies = config.ayko.localisation.availableCurrencies;
      const locale = availableCurrencies.find(
        (currency) => currency.name === this.getUserCurrency
      );
      return locale ? locale.defaultLocale : 'en-GB';
    },
    payload() {
      return {
        cartItemId: this.item.id
      };
    },
    isBike() {
      return !!(
        this.item.product.__typename === 'BundleProduct' && this.item.full_spec
      );
    },
    isBannedShipping() {
      // Check if the product is banned from shipping in mixin
      return this.checkProductForBannedShipping(this.item.product);
    },
    itemPrice() {
      return this.item.prices.price_including_tax.value;
    },
    price() {
      const qty = this.item.quantity || 1;
      const price = this.itemPrice;
      const total = qty * price;
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.getUserCurrency
      }).format(total);
    }
  },
  methods: {
    ...mapActions('graphqlCart', ['removeItemFromCart']),
    ...mapActions('builderUi', ['toggleFullSpecCartModal']),
    removeFromCart() {
      this.productRemoved = null;
      this.removeItemFromCart(this.payload);
      this.productRemoved = this.item.product;
    }
  }
};
</script>
